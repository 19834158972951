<template>
  <div>
    <button v-if='isViewMode' 
      @click='openUrl()'
      class='underline hover:bg-blue-100'>
      {{field.value}}
    </button>
    <div v-else 
      class='flex'>
      <span class='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
        URL
      </span>
      <input 
        type='text' 
        v-model='field.value'
        @blur='handleUpdate'
        class='form-input' style='width: max(4rem, 66%);'>
      <button @click='openUrl()'
        class='border px-4 border-l-0 rounded-r-md font-bold bg-gray-200 text-sm'
        style='margin-left: -2px;'>
        이동
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CustomFieldUrl',
  props: ['field', 'isViewMode'],
  methods: {
    handleUpdate () {
      this.$emit('update-value', this.field)
    },
    openUrl () {
      window.open(this.field.value, '_blank')
    },
  },
}
</script>
